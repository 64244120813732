<template>
  <div class="app-card">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col :span="8">
            <a-form-model-item label="商家名称" prop="name">
              <a-input v-model="searchForm.name" />
            </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <!-- <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a> -->
        </a-col>
      </a-row>
    </a-form>
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button icon="plus" type="primary" @click="addBusinessClick" v-if="$root.$data.privateState.action.includes('/BusinessShop/Create') || $root.$data.privateState.role === 'SuperAdmin'">添加商家</a-button>
          <a-divider type="vertical" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        bordered
        :scroll="{ x: 1200 }"
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #insScores="insScores">
          <div v-for="s in insScores" :key="s.key">
            {{s.name}}<a-rate  v-model="s.value" style="fontSize: 12px" />
          </div>
          
        </template>
        <template #shopThumbnail="shopThumbnail">
          <a-avatar :src="`${$appBaseUrl}${shopThumbnail}`" shape="square" :size="128" icon="user" />
        </template>
        <template #action="data">
          <a-button type="link" @click="toEdit(data)" block>编辑</a-button>
          <a-button type="link" @click="toDetail(data)" block>详情</a-button>
          <a-popconfirm
            title="是否确认删除该条?"
            @confirm="toDelete(data)"
            okText="是"
            cancelText="否"
          >
            <a-button type="link" block>删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
    
    <a-modal width="80%" :title="createAndEditModalTitle" v-model="createAndEditShow" @ok="createAndEditModalOk" >
      <a-form-model ref="createAndEditForm" :model="createAndEditForm" :rules="createAndEditFormRules" v-bind="formLayout">
        <a-form-model-item label='产品分类' prop="type">
          <a-select v-model="createAndEditForm.type" placeholder="请选择">
            <a-select-option v-for="it in businessTypeList" :key="it.key">{{it.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='商家名称' prop="title">
          <a-input v-model="createAndEditForm.title" />
        </a-form-model-item>
        <a-divider orientation="left">内容</a-divider>
            <div style="height:500px;">
              <quill-editor
                style="height:440px;"
                v-model="createAndEditForm.content"
                :options="{placeholder: '请填写内容', ...editerOptions}"
              />
            </div>
      </a-form-model>
    </a-modal>

    <a-drawer
      width="640"
      :title="shopDetail.name"
      placement="right"
      :closable="false"
      :visible="shopDetailShow"
      @close="shopDetailShow = false"
    >
      <a-carousel autoplay v-if="shopDetail.shopImages && shopDetail.shopImages.length > 0">
        <div v-for="it of shopDetail.shopImages" :key="it">
          <h3><img :src="`${$appBaseUrl}${it}`" alt=""></h3>
        </div>
      </a-carousel>
      <a-descriptions :column="1" title="">
        <a-descriptions-item label="商家名称">{{shopDetail.name}}</a-descriptions-item>
        <a-descriptions-item label="商家类型">{{shopDetail.typeName}}</a-descriptions-item>
        <a-descriptions-item label="服务描述">{{shopDetail.subTitle}}</a-descriptions-item>
        <a-descriptions-item label="是否可预约">{{shopDetail.canAppointment ? '是' : '否'}}</a-descriptions-item>
        <a-descriptions-item label="当前订单数">{{shopDetail.orderCount}}</a-descriptions-item>
        <a-descriptions-item label="地址">{{shopDetail.address}}</a-descriptions-item>
        <a-descriptions-item label="商家介绍">
          <div v-html="shopDetail.content"></div>
        </a-descriptions-item>
      </a-descriptions>
      <div>
        <a-divider>地图位置</a-divider>
        <baidu-map class="map" :zoom="13" :center="shopDetail.addressPosition ? shopDetail.addressPosition : '昆明'">
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-marker v-if="shopDetail.addressPosition" :position="shopDetail.addressPosition" :dragging="true">
            </bm-marker>
          </baidu-map>
      </div>
    </a-drawer>

    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import { BusinessShop } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"name":""}'

const columns = [
  {
    title: '缩略图',
    dataIndex: 'shopThumbnail',
    width: 150,
    scopedSlots: { customRender: 'shopThumbnail' },
  },
  {
    title: '商家名称',
    dataIndex: 'name',
    width: 150,
  },
  {
    title: '经营类型',
    dataIndex: 'businessType',
    width: 150,
  },
  {
    title: '服务描述',
    dataIndex: 'subTitle',
    width: 150,
  },
  {
    title: '服务质量评分',
    dataIndex: 'score',
    width: 120,
  },
  {
    title: '保险公司评分',
    dataIndex: 'insScores',
    width: 200,
    scopedSlots: { customRender: 'insScores' },
  },
  {
    title: '订单数',
    dataIndex: 'orderCount',
    width: 150,
  },
  {
    title: '服务电话',
    dataIndex: 'servicePhone',
    width: 150,
  },
  {
    title: '添加时间',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]

export default {
  name: 'BusinessShopTableList',
  mixins: [aliveVM, mixinTableList],
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.fetch();
    });
  },
  async mounted () {
    const baseColumns = []
    this.$set(this, 'columns', JSON.parse(JSON.stringify(columns)))
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    await this.getbusinessTypeList()
    this.fetch()
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      moment,
      tableListApi: `${BusinessShop}/Table`,
      searchParams: {},
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      columns: [],
      baseColumns: [],
      // other
      formLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      editerOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["link"],
            ["clean"], //清除字体样式
            ["image"], //上传图片、上传视频
          ],
        },
        theme: "snow",
      },
      businessTypeList: [],
      productTypeDic: {},
      createAndEditModalTitle: '添加Wiki',
      createAndEditShow: false,
      createAndEditForm: {
        type: '',
        title: '',
        content: ''
      },
      createAndEditFormRules: {
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        title: [{ required: true, message: "请填写", trigger: "blur" }]
      },
      shopDetail: {
        name: '',
        content: '',
        addressPosition: '北京'
      },
      shopDetailShow: false,
      nowFormType: '',
      nowEditUuid: '',
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', {})
      
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', {})
      
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      let searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'startAt' || k === 'endAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      if (licia.isEmpty(searchParams)) {
        searchParams = {}
      }
      
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    async getbusinessTypeList() {
      const res = await this.$axios.get(`${BusinessShop}/GetBusinessTypeList`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!res.status) {
        this.$message.error(res.message)
        return
      }
      this.businessTypeList = res.data
      const productTypeDic = {}
      for (const it of res.data) {
        productTypeDic[it.key] = it.value
      }
      this.productTypeDic = productTypeDic
    },
    async addBusinessClick() {
      this.$router.push({ name: 'BusinessShopAddAndEdit' }).catch(err => err)
      // this.nowFormType = 'create'
      // this.createAndEditModalTitle = '添加Wiki'
      // this.createAndEditShow = true
    },
    createAndEditModalOk() {
      this.$refs.createAndEditForm.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请检查填写内容");
          return;
        }
        if (!this.createAndEditForm.content) {
          this.$message.error("请检查填写内容");
          return;
        }
        if (this.nowFormType === 'create') {
          const apiRes = await this.$axios
          .post(`${BusinessShop}/Create`, this.createAndEditForm)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
          if (!apiRes.status) {
            this.$message.error(apiRes.message);
            this.sending = false;
            return;
          }
        } else {
          const apiRes = await this.$axios
          .post(`${BusinessShop}/Edit?uuid=${this.nowEditUuid}`, this.createAndEditForm)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
          if (!apiRes.status) {
            this.$message.error(apiRes.message);
            this.sending = false;
            return;
          }
        }
        
        this.$message.success(this.nowFormType === 'create' ? '添加成功' : '编辑成功')
        this.createAndEditShow = false
        this.fetch()
      })
    },
    async getDetail (uuid) {
      const apiRes = await this.$axios.get(`${BusinessShop}/Detail?uuid=${uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      return apiRes.data;
    },
    async toEdit (data) {
      this.$router.push({ name: 'BusinessShopAddAndEdit', params: {uuid: data.uuid} }).catch(err => err)
    },
    async toDelete (data) {
      this.loading = true
      const res = await this.$axios.delete(`${BusinessShop}/Delete?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.loading = false
      if (!res.status) {
        this.$message.error(res.message)
        return
      }
      this.fetch()
    },
    // 详情
    async toDetail (data) {
      const detail = await this.getDetail(data.uuid)
      if (detail === null) return
      this.$set(this, 'shopDetail', detail)
      this.shopDetailShow = true
    },
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 350px;
}
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>